import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import SearchBar from "../SearchBar/SearchBar";
import "./Header.css";

const Header = ({ siteTitle }) => (
  <header className="header">
    <Link className="header__link" to="/">
      <FontAwesomeIcon icon={faHome} label="Home" />
    </Link>
    <div className="search-bar">
      <SearchBar />
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

export default Header;
