import PropTypes from "prop-types";
import { Link } from "gatsby";
import React from "react";

const SearchItem = ({ path, title, query }) => (
  <div>
    <p>
      <Link to={path}>{title}</Link>
    </p>
  </div>
);

SearchItem.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  query: PropTypes.string,
};

export default SearchItem;
