import { Index } from "elasticlunr";
import React, { useState, useCallback } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

// custom components
import Input from "../Input/Input";
import SearchItems from "../SearchItems/SearchItems";

const Search = ({ searchIndex }) => {
  const index = Index.load(searchIndex);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  // this function is passed to Input component as a ref
  // when the Input component renders, this function is
  // called to set focus
  const callbackRef = useCallback(inputElement => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  function searchResults(searchQuery) {
    const res = index.search(searchQuery, { expand: true }).map(({ ref }) => {
      return index.documentStore.getDoc(ref);
    });
    setResults(res);
  }

  return (
    <div className="search-box">
      <div>
        <Input
          ref={callbackRef}
          label="Search"
          onChange={event => {
            const searchQuery = event.target.value;
            setQuery(searchQuery);
            searchResults(searchQuery);
          }}
          placeholder="Search"
          value={query}
        />
      </div>
      <SearchOverlay role="presentation" results={results}>
        <SearchItems query={query} results={results} />
      </SearchOverlay>
    </div>
  );
};

const SearchOverlay = styled.div`
  opacity: ${props => (props.results.length > 0 ? 1 : 0)};
  display: ${props => (props.results.length > 0 ? "flex" : "none")};
  position: absolute;
  height: 30vh;
  width: 500px;
  overflow-y: scroll;
  background: rgba(255, 255, 255, 0.9);
`;

Search.propTypes = {
  searchIndex: PropTypes.object,
};

export default Search;
