import PropTypes from "prop-types";
import React from "react";
import "./Input.css";

const Input = React.forwardRef(
  ({ label, onChange, placeholder = "", value }, ref) => (
    <input
      ref={ref}
      aria-label={label}
      onChange={onChange}
      placeholder={placeholder}
      type="text"
      value={value}
      className="search-input"
    />
  )
);

Input.displayName = "Input";
Input.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default Input;
