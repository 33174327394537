import React from "react";

import "./Footer.css";

const Footer = () => (
  <footer className="footer">
    <p>&copy; 2020 Experiences of an Ops Guy.</p>
    <a
      className="attribution-link"
      href="https://www.freepik.com/vectors/technology"
    >
      Image by Freepik
    </a>
  </footer>
);

export default Footer;
