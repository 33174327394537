import { graphql, StaticQuery } from "gatsby";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ClickAwayListener from "react-click-away-listener";
import Search from "../Search/Search";

import "./SearchBar.css";

const SearchBar = () => {
  const [showSearch, setShowSearch] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setShowSearch(false)}>
      <div className="search">
        <div className="search__icon">
          <a className="search__icon" href="#">
            <FontAwesomeIcon
              icon={faSearch}
              label="Search Icon"
              onClick={() => setShowSearch(!showSearch)}
            />
          </a>
        </div>
        <div className="search__container">
          <StaticQuery
            query={graphql`
              query SearchIndexQuery {
                siteSearchIndex {
                  index
                }
              }
            `}
            render={data => (
              <div>
                {showSearch && (
                  <Search searchIndex={data.siteSearchIndex.index} />
                )}
              </div>
            )}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default SearchBar;
