import PropTypes from "prop-types";
import React from "react";
import SearchItem from "../SearchItem/SearchItem";

import "./SearchItems.css";

const SearchItems = ({ results, query }) => (
  <ul className="search-results">
    {results.map(page => (
      <li key={page.id}>
        <SearchItem path={`${page.path}`} query={query} title={page.title} />
      </li>
    ))}
  </ul>
);

SearchItems.propTypes = {
  results: PropTypes.array,
  query: PropTypes.string,
};

export default SearchItems;
